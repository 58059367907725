//
// user-variables.scss
// Use this to override Bootstrap and Cartzilla variables
//

// Example of a variable override to change Cartzilla background color
// Remove the "//" to comment it in and see it in action!
//$primary: #75AD45;
//$gray-800: black;

$accent: #3490dc;
